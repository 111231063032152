import { AnyAction, Reducer } from "redux";

import {
  APPLICATIONS_LIST,
  AUTHORIZATION_LIST,
  COMFLOW_SESSION_ID,
  INITIALIZED,
  IS_SINGLE_SIGN_ON,
  LOGIN_TOKEN,
  REMEMBER_ME,
  SESSION_ID,
  SESSION_INITIALIZED,
  SINGLE_SIGN_OUT,
  SSO_SESSION_DATA,
  USER_HAS_MESSAGE_TO_ACCEPT,
} from "./sessionVariables";

export type ApplicationItem = {
  label: string;
  id: string;
};
type SessionState = {
  comflowSessionId: string | null;
  loginToken: string | null;
  rememberMe: boolean | null;
  sessionId: string | null;
  initialized: boolean;
  authorizationList: any;
  applicationsList: ApplicationItem[] | null;
  userMessageToAccept: any;
  isSingleSignOn: boolean | undefined;
  ssoSessionData: any;
  singleSignOut: any;
};

const initialState: SessionState = {
  comflowSessionId: null,
  loginToken: null,
  rememberMe: null,
  sessionId: null,
  initialized: false,
  authorizationList: null,
  applicationsList: null,
  userMessageToAccept: null,
  isSingleSignOn: undefined,
  ssoSessionData: null,
  singleSignOut: null,
};

export const sessionReducer: Reducer<SessionState, AnyAction> = (
  state = initialState,
  action: AnyAction,
): SessionState => {
  switch (action.type) {
    case COMFLOW_SESSION_ID:
      return {
        ...state,
        comflowSessionId: action.payload,
      };

    case LOGIN_TOKEN:
      return {
        ...state,
        loginToken: action.loginToken,
      };

    case REMEMBER_ME: {
      const rememberMe = action.rememberMe instanceof String ? action.rememberMe === "true" : action.rememberMe;

      return {
        ...state,
        rememberMe: rememberMe,
      };
    }

    case APPLICATIONS_LIST: {
      return {
        ...state,
        applicationsList: action.payload,
      };
    }

    case AUTHORIZATION_LIST: {
      return {
        ...state,
        authorizationList: action.authorizationList,
      };
    }

    case INITIALIZED: {
      return {
        ...state,
        initialized: action.initialized,
      };
    }

    case USER_HAS_MESSAGE_TO_ACCEPT: {
      return {
        ...state,
        userMessageToAccept: action.message,
      };
    }

    case IS_SINGLE_SIGN_ON: {
      return {
        ...state,
        isSingleSignOn: action.isSingleSignOn,
      };
    }

    case SINGLE_SIGN_OUT: {
      return {
        ...state,
        singleSignOut: action.singleSignOut,
      };
    }

    case SSO_SESSION_DATA: {
      return {
        ...state,
        ssoSessionData: action.ssoSessionData,
      };
    }

    case SESSION_ID: {
      return {
        ...state,
        sessionId: action.sessionId,
      };
    }

    case SESSION_INITIALIZED: {
      return {
        ...state,
        initialized: true,
      };
    }

    default:
      return state;
  }
};
