import { Button, Collapse, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropTypes from "prop-types";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { ChevronDown, ChevronUp } from "../../../../assets/icons";
import { useTranslate } from "../../../../language/i18n";
import { selectHasComflowAccess, selectHasFSPAccess } from "../../../../reducers/session/sessionSelectors";
import accountService from "../../../../services/account/accountService";
import { createMenu } from "../../../../utils/menu";
import pages from "../../../../utils/pages";
import LinkToComflow from "../../../Comflow/LinkToComflow";
import Drawer from "../../../shared/Drawer/Drawer";
import styles from "./MenuDrawer.module.scss";

function MenuDrawer({ visible, onMenuLinkClick }) {
  const session = useSelector(state => state.session);
  const translate = useTranslate();
  const history = useHistory();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const hasComflowAccess = useSelector(selectHasComflowAccess);
  const hasFSPAccess = useSelector(selectHasFSPAccess);
  const menuItems = createMenu("MENU_DRAWER");

  const handleLogout = async () => {
    const response = await accountService.logout(session.sessionId);
    if (response.isOk()) {
      history.push(pages.HOME);
    }
  };

  const toggleMenu = item => {
    const isActiveItem = item && item?.id === activeMenuItem?.id;
    const hasSubMenus = item && !(item?.to || item?.subMenus.length < 0);

    if (isActiveItem && hasSubMenus) {
      setSubMenuVisible(!subMenuVisible);
    } else if (!hasSubMenus) {
      onMenuLinkClick();
    } else if (!isActiveItem && hasSubMenus) {
      setSubMenuVisible(true);
    } else {
      setSubMenuVisible(false);
      onMenuLinkClick();
    }
    setActiveMenuItem(item);
  };

  return (
    <Drawer title={translate("MENU")} visible={visible} customCssClass={styles.drawerMenu}>
      <nav className={styles.navContent}>
        {hasFSPAccess && (
          <ul className={styles.menu}>
            {menuItems.map(item => (
              <li key={item.id} className={styles.menuItem}>
                {item.subMenus && item.subMenus.length > 0 ? (
                  <>
                    <button
                      className={classNames(
                        styles.menuButton,
                        activeMenuItem && activeMenuItem.id === item.id && subMenuVisible && styles.active,
                      )}
                      onClick={() => toggleMenu(item)}
                    >
                      {translate(item.label)}
                      {activeMenuItem && activeMenuItem.id === item.id && subMenuVisible ? (
                        <ChevronUp customCssClass={styles.icon} />
                      ) : (
                        <ChevronDown customCssClass={styles.icon} />
                      )}
                    </button>
                    {activeMenuItem?.id === item.id && (
                      <Collapse visible={subMenuVisible} customCssClass={styles.submenuWrapper}>
                        <div className={styles.subMenuContent}>
                          {activeMenuItem.subMenus.map((subMenuItem, id) => (
                            <figure key={`submenu-${id}`} className={styles.subMenu}>
                              {subMenuItem.title && (
                                <figcaption className={styles.title}>{translate(subMenuItem.title)}</figcaption>
                              )}
                              <ul>
                                {subMenuItem?.items.map(
                                  (item, id) =>
                                    !item.hidden && (
                                      <SubMenuItem item={item} key={`submenu-link-${id}`} toggleMenu={toggleMenu} />
                                    ),
                                )}
                              </ul>
                            </figure>
                          ))}
                        </div>
                      </Collapse>
                    )}
                  </>
                ) : (
                  <NavLink to={item.to || "/"} className={styles.menuLink} onClick={() => toggleMenu(item)}>
                    {translate(item.label)}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        )}
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <button onClick={handleLogout} className={styles.menuButton}>
              {translate("LOGOUT")}
            </button>
          </li>
          <li className={styles.version}>Version: {process.env.REACT_APP_VERSION}</li>
        </ul>
        {hasComflowAccess && (
          <div className={styles.linkToComflow}>
            <LinkToComflow />
          </div>
        )}
      </nav>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  visible: PropTypes.bool,
  onMenuLinkClick: PropTypes.func,
};

const SubMenuItem = ({ item, toggleMenu }) => {
  const translate = useTranslate();
  let content = <p>{translate(item.label)}</p>;
  if (item.link) content = <Link to={item.link}>{translate(item.label)}</Link>;
  if (item.action)
    content = (
      <Button theme={THEME.PLAIN} onClick={item.action}>
        {translate(item.label)}
      </Button>
    );

  return (
    <li className={styles.link} onClick={() => toggleMenu()}>
      {content}
    </li>
  );
};

SubMenuItem.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    action: PropTypes.func,
  }),
  toggleMenu: PropTypes.func.isRequired,
};

export default memo(MenuDrawer);
