import accountService from "../../../services/account/accountService";
import SpinningButton, { SpinningButtonVariant } from "../SpinningButton/SpinningButton";

const LogoutButton = ({ variant }: { variant: SpinningButtonVariant }) => {
  const logout = async () => {
    await accountService.logout();
  };

  return (
    <SpinningButton buttonAction={logout} variant={variant}>
      Logga ut
    </SpinningButton>
  );
};

export default LogoutButton;
