import PropType from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import { setMessage } from "../../../../reducers/dialog/dialogAction";
import orderService from "../../../../services/order/orderService";
import { ADDRESS_TYPE, CLIENT_TYPE, CONTACT_TYPE } from "../../../../utils/constants";
import { DELIVERY_LOCATION, ORDER_CONTROL } from "../../ProductDetail/Components/productConstants";
import styles from "./ProductCatalogueAddToCart.module.scss";
import ProductCatalogueOrderButton from "./ProductCatalogueOrderButton";
import ProductCatalogueQuantity from "./ProductCatalogueQuantity";

const ProductCatalogueAddToCart = ({ product }) => {
  const order = useSelector(state => state.appData.order);
  const translate = useTranslate();
  const appDataUser = useSelector(state => state.appData.user);
  const [quantity, setQuantity] = useState(1);

  const onOrder = async (webOrder = order) => {
    const isBaseStorage = webOrder?.receiver?.type === CLIENT_TYPE.BASE_STORAGE;
    const receiverLocation = DELIVERY_LOCATION.DELIVERY;
    const data = {
      contentType: receiverLocation.id,
      subContentType: {
        id: ORDER_CONTROL.NONE.id,
      },
      product: {
        id: product.id,
        quantity,
      },
    };
    const response = await orderService.createContent(data);
    if (response.isOk()) {
      if (isBaseStorage) {
        const addressAndContactDataForBaseStorage = {
          contact: { id: appDataUser.information.id, type: CONTACT_TYPE.PERSON },
          address: {
            id: webOrder?.receiver?.id,
            type: ADDRESS_TYPE.COMPANY,
          },
        };

        await orderService.updateDelivery(addressAndContactDataForBaseStorage, webOrder, response.data.id);
      }

      setMessage(translate("CART_ADDED_ITEM", { item: response.data?.product?.name }));
    }

    return response;
  };

  const onClickOrderButton = async (_productRef, webOrder) => {
    await onOrder(webOrder);
  };
  return (
    <div className={styles.buttonRow}>
      <div className={styles.buttonCol}>
        <ProductCatalogueQuantity setQuantity={setQuantity} />
        <div className={styles.orderButton}>
          <ProductCatalogueOrderButton hasOrder={order != null} onClick={onClickOrderButton} product={product} />
        </div>
      </div>
    </div>
  );
};

ProductCatalogueAddToCart.propTypes = {
  product: PropType.shape({
    id: PropType.string,
  }),
};

export default ProductCatalogueAddToCart;
