import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useFetchSessionData from "../hooks/useFetchSessionData";
import useIsSingleSignOn from "../hooks/useIsSingleSignOn";

import useComflowLogin from "../components/Comflow/hooks/useComflowLogin";
import { setCompany, setFacility, setInformation, setOrder } from "../reducers/appData/appDataActions";
import { useAppDispatch } from "../reducers/hooks/useAppDispatch";
import {
  setApplicationsList,
  setAuthorizationList,
  setInitialized,
  setLoginToken,
  setRememberMe,
  setSessionId,
  setUserHasMessageToAccept,
} from "../reducers/session/sessionAction";
import accountService from "../services/account/accountService";
import storage from "../utils/store/storage";
import { REMEMBER_ME, SESSION_ID, SINGLE_SIGN_ON_RESPONSE } from "../utils/store/storeVariables";

const Init = () => {
  const refLoginToken = useRef();
  const [stateLoginToken, setStateLoginToken] = useState();
  const isSingleSignOn = useIsSingleSignOn();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useSelector(state => state.session?.initialized);
  const singleSignOut = useSelector(state => state.session?.singleSignOut);
  useComflowLogin();

  const dispatch = useAppDispatch();

  const initializeSingleSignOnSession = useCallback(async () => {
    setRememberMe(false);

    //Doesn't need a session, is created on the fly from mod_auth_openidc in reversed proxy
    await loadSessionData();
  }, [loadSessionData]);

  const querystringToken = queryString.parse(location.search).loginToken;

  useEffect(() => {
    if (!!querystringToken && querystringToken !== stateLoginToken) {
      setStateLoginToken(querystringToken);
      removeLoginTokenFromQueryString();
    }
  }, [querystringToken, stateLoginToken]);

  useEffect(() => {
    const initializeSingleSignOnAndStandard = async () => {
      await initializeSingleSignOnSession();
      await initializeStandardLoginSession();
    };

    const doInit = async () => {
      if (isSingleSignOn && !sessionInitialized) {
        initializeSingleSignOnAndStandard();
      } else if (!isSingleSignOn && isMylocLogin()) {
        initializeMylocLoginSession(dispatch, stateLoginToken, refLoginToken);
      } else if (!isSingleSignOn && !!stateLoginToken && stateLoginToken !== refLoginToken.current) {
        await doLoginWithSessionToken(dispatch, stateLoginToken, refLoginToken);
      } else if (!isSingleSignOn && !sessionInitialized) {
        initializeStandardLoginSession(dispatch);
      }
    };
    if (isSingleSignOn === undefined) {
      return;
    }

    doInit();
  }, [dispatch, initializeSingleSignOnSession, isSingleSignOn, sessionInitialized, stateLoginToken]);

  useEffect(() => {
    if (singleSignOut) {
      storage.deleteItem(SESSION_ID);
      storage.deleteItem(SINGLE_SIGN_ON_RESPONSE);

      const domain = window.location.hostname;
      const redirectLocation =
        "/MyL/redirect_uri?logout=https%3A%2F%2F" + domain + encodeURIComponent("/MyL") + "%2Flogged-out.html";

      window.location = redirectLocation;
    }
  }, [singleSignOut]);

  return null;
};

const initSessionData = (data, dispatch) => {
  setCompany({
    id: data.company?.id,
    description: data.company?.label,
  });

  setFacility({
    id: data?.facility?.id,
    description: data?.facility?.label,
    value: data?.facility?.value,
    category: data?.facility?.category,
  });

  if (data.user) setInformation(data.user);

  if (data.order) setOrder(data.order);

  // Session

  setSessionId(data.id);

  setAuthorizationList(data.authorizations);

  dispatch(setApplicationsList(data.applications));
  setUserHasMessageToAccept(data.accessMessage);

  setInitialized();
};

const doLoginWithSessionToken = async (dispatch, stateLoginToken, refLoginToken) => {
  const data = {
    token: stateLoginToken,
  };
  doLoginWithToken(data, dispatch, refLoginToken);
};

const doLoginWithMylocToken = async (mylocLoginToken, dispatch, refLoginToken) => {
  const data = {
    mylocLoginToken: mylocLoginToken,
    persistent: false,
  };
  doLoginWithToken(data, dispatch, refLoginToken);
};

const doLoginWithToken = async (data, dispatch, refLoginToken) => {
  if (refLoginToken.current === data.token) {
    return;
  }

  refLoginToken.current = data.token;

  const response = await accountService.loginWithToken(data);
  if (response.data?.id) {
    const appData = await accountService.getAppData();

    initSessionData(appData.data, dispatch);
  }
};

const mylocAuthentication = async (mylocLoginToken, dispatch, refLoginToken) => {
  if (mylocLoginToken) {
    setLoginToken(mylocLoginToken, false);
    await doLoginWithMylocToken(mylocLoginToken, dispatch, refLoginToken);
  }
  return;
};
const isMylocLogin = () => {
  return getMylocLoginToken() !== undefined;
};
const getMylocLoginToken = () => {
  return queryString.parse(location.search).mylocLoginToken;
};

/*
const isSessionTokenLogin = () => {
  return getSessionLoginToken() !== undefined;
};
*/

const removeLoginTokenFromQueryString = () => {
  const url = new URL(window.location.href);

  // Remove the 'loginToken' parameter
  url.searchParams.delete("loginToken");

  // Update the browser's URL without reloading the page
  window.history.replaceState({}, "", url.toString());
};

/*
async function initializeSessionTokenLogin(dispatch, stateLoginToken, refLoginToken) {
  await doLoginWithSessionToken(dispatch, refLoginToken);
}
  */

async function initializeMylocLoginSession(dispatch, refLoginToken) {
  let query = queryString.parse(location.search);
  //If a virtualSessionId exists, use it instead of logging in again
  if (query.virtualSessionId) {
    setSessionId(encodeURIComponent(query.virtualSessionId));
    const response = await accountService.getAppData();
    setAuthorizationList(response.data.authorizations);
    setInitialized();
  } else {
    await mylocAuthentication(query.mylocLoginToken, dispatch, refLoginToken);
  }
  return;
}

async function initializeStandardLoginSession(dispatch) {
  let rememberMe = await storage.loadItem(REMEMBER_ME);
  setRememberMe(!!rememberMe);

  const sessionId = await storage.loadItem(SESSION_ID);
  if (sessionId) {
    const response = await accountService.getAppData();
    if (response.isOk()) {
      initSessionData(response.data, dispatch);
    } else {
      storage.saveItem(SESSION_ID, null, rememberMe);
    }
  }

  setInitialized();
}

export default Init;
