import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import SpinningButton from "../shared/SpinningButton/SpinningButton";
import useComflowLogin from "./hooks/useComflowLogin";
import { isRedirectToComflowAllowed } from "./utils/comflowUtils";

const LinkToComflow: React.FC = () => {
  const { isLoggedIn, initiateLogin } = useComflowLogin();

  const [loading, setLoading] = useState<boolean>(false);
  const message = useSelector((state: RootState) => state.dialog.message);
  const applicationsList = useSelector((state: RootState) => state.session.applicationsList);
  const comflowApp = applicationsList?.find(app => app.id === "COMFLOW");

  useEffect(() => {
    if (message?.error) {
      setLoading(false);
    }
  }, [message]);

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(false);
      window.open(process.env.REACT_APP_COMFLOW_WEBAPP, "_blank");
    }
  }, [isLoggedIn]);

  const handleClick = () => {
    initiateLogin();
    setLoading(true);
  };

  if (!isRedirectToComflowAllowed()) return null;

  return (
    <SpinningButton
      variant="outlined"
      loading={loading}
      icon={<OpenInNewIcon />}
      iconPosition="end"
      buttonAction={handleClick}
      widthPX={200}
    >
      Öppna {comflowApp?.label}
    </SpinningButton>
  );
};

export default LinkToComflow;
