import { RootState } from "../rootReducer";

export const selectHasComflowAccess = (state: RootState) => {
  const applicationsList = state.session.applicationsList;

  return applicationsList?.some(app => app.id === "COMFLOW") ?? false;
};

export const selectHasFSPAccess = (state: RootState) => {
  const applicationsList = state.session.applicationsList;

  return applicationsList?.some(app => app.id === "FSP") ?? false;
};
