import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { generatePath, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import styles from "./OrderSelector.module.scss";
import SelectField from "../../shared/SelectField/SelectField";
import pages from "../../../utils/pages";

const OrderSelector = ({ onNext }) => {
  const history = useHistory();
  const queryParams = useParams();
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    orderService.getCategories().then(response => {
      setCategories(response.data.categories);
      setSelected(response.data.selected);
    });
  }, []);

  useEffect(() => {
    if (!categories || !selected) {
      return;
    }

    const category = categories.find(category => category.id === selected.id);
    setSelectedCategory(category);

    setSelectedType(category?.types?.find(type => type.id === selected.typeId));
  }, [categories, selected]);

  useEffect(() => {});

  function handleCategories(category) {
    if (category?.id !== selectedCategory?.id) setSelectedType(null);
    setSelectedCategory(category);
  }

  const handleNext = async () => {
    setIsLoading(true);

    const reqBody = {
      contentType: "FUNCTION",
      function: {
        id: selectedCategory.id,
        type: {
          id: selectedType?.id,
        },
      },
    };

    const response = queryParams.content
      ? await orderService.updateContent(queryParams.content, reqBody)
      : await orderService.createContent(reqBody);

    setIsLoading(false);

    if (response.isOk()) {
      if (!queryParams.content) {
        const path = generatePath(`${pages.FUNCTION_ORDER.PATH}`, {
          order: queryParams.order,
          content: response.data.id,
        });

        !queryParams.content && history.replace(path);
      }

      onNext(response.data);
    }
  };

  const btnDisabled =
    !receiver || !selectedCategory || (selectedCategory && selectedCategory?.types?.length > 0 && !selectedType);

  return (
    <div className={styles.content}>
      <SelectField
        options={categories}
        onSelect={handleCategories}
        label={translate("FUNCTION")}
        selectedId={selectedCategory?.id}
        required
      />

      <SelectField
        options={selectedCategory?.types}
        onSelect={setSelectedType}
        label={translate("FUNCTION_TYPE")}
        selectedId={selectedType?.id}
        disabled={!selectedCategory?.types?.length > 0}
        required
      />
      <Button isLoading={isLoading} onClick={handleNext} customCssClass={styles.submitBtn} disabled={btnDisabled}>
        {translate("NEXT")}
      </Button>
    </div>
  );
};

OrderSelector.propTypes = {
  content: PropTypes.object,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      types: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, values: PropTypes.string })),
    }),
  ),
  onNext: PropTypes.func,
};

export default OrderSelector;
