import PropType from "prop-types";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../language/i18n";
import taskService from "../../../services/taskService";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";
import styles from "./TaskDetails.module.scss";
import TaskTypeCA from "./TaskTypeCA";
import TaskTypeCH from "./TaskTypeCH";

const TaskDetails = ({ setBreadcrumbs, fromReceiverMenu }) => {
  const translate = useTranslate();
  const queryParams = useParams();
  const [task, setTask] = useState([]);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const response = await taskService.getTask(queryParams.id);
      setTask(response.data);
    })();
  }, [queryParams.id]);

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([
        {
          text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_TASKS.NAME)}`,
          link: location.state ? location.state?.path : pages.MY_TASKS.PATH,
        },
        {
          text: translate("REPORT_TASK"),
        },
      ]);
    } else {
      setBreadcrumbs([
        {
          text: location.state
            ? location.state?.text
            : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_TASKS.NAME)}`,
          link: location.state ? location.state?.path : pages.MY_TASKS.PATH,
        },
        {
          text: translate("REPORT_TASK"),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBreadcrumbs, translate]);

  if (!task.orderNumber) {
    return <div>Loading</div>;
  }

  return (
    <Page customCssClass={styles.page} title={translate("REPORT_TASK")}>
      {task.type === "CA" && <TaskTypeCA task={task} />}
      {task.type === "CH" && <TaskTypeCH task={task} />}
    </Page>
  );
};

TaskDetails.propTypes = {
  setBreadcrumbs: PropType.func,
  fromReceiverMenu: PropType.bool,
};

export default TaskDetails;
